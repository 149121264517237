@import url("https://fonts.cdnfonts.com/css/inter");
:root {
    --primary-color: #1ab966;
    --second-color: #d8d206;
    --white-color: #fff;
    --black-color: #000;
    --text-color: #333;
    --border-color: #dbdbdb;
    --header-height: 120px;
}
* {
    font-family: "Inter", sans-serif;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: var(--primary-color);
}

*::-webkit-scrollbar {
    display: none;
}

.btn-animation {
    animation: btnanimation 0.5s infinite alternate;
}

@keyframes btnanimation {
    from {
        opacity: 0.8;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
